html {
  font-size: 62.5%;
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  min-width: 300px;
  scroll-behavior: smooth;
}

body {
  font-size: 1.6rem;
  line-height: 1.5;
  color: @text;
  font-family: @main;
  min-width: 300px;
}

img {
  display: block;
}

#mainwrap {
  overflow: hidden;
}

.inner {
  position: relative;
  margin: auto;
  max-width: 1400px;
  width: 100%;
}
