.block.contact {
  h2,
  h3,
  h4,
  h5 {
    font-size: 2.56rem;
  }

  .title {
    margin-bottom: 12.8px;
  }

  p,
  a {
    line-height: 1.5;
  }

  .inner {
    display: flex;
    flex-direction: column;
    padding: 32px;
    .details,
    .contact-form {
      margin: 0 10px;
    }
    .contact-form {
      .title {
        padding-left: 8px;
      }
    }
  }

  .details {
    .phone,
    .fax {
      margin-bottom: 24px;
      * {
        font-size: 1.44rem;
      }
    }
  }

  .tablet({
    h2,
    h3,
    h4,
    h5 {
      font-size: 2.88rem;
    }
    .inner {
      display: flex;
      flex-direction: row;
      .details {
        width: 40%;
        .phone *,
        .fax * {
              font-size: 1.6rem;
        }
      }
      .contact-form {
        width: 60%;
      }
    }
  });
}
