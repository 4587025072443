.block.footer {
  .top {
    background-color: @primary-bg;
    .inner {
      padding: 64px 26px;
      .logo,
      .contact {
        margin: 0 10px 32px;
      }
      .logo {
        img {
          width: 128px;
          height: auto;
        }
      }
      .title {
        margin-bottom: 12.8px;
      }
      h2,
      h3,
      h4,
      h5 {
        font-weight: 900;
      }
      nav {
        margin: 0 10px;
        ul {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          column-gap: 20px;
          li {
            a {
              color: @text;
              text-decoration: underline;
              &:hover {
                color: @text;
                opacity: 0.5;
              }
            }
          }
        }
      }
    }
  }
  .lower {
    background-color: @page-bg;
    .inner {
      padding: 32px 42px;
      display: flex;
      flex-direction: column;
      .left {
        margin-bottom: 24px;
      }
      .right {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .icons {
          display: flex;
          flex-direction: row;
          margin-right: 5px;
          gap: 2px;
          .icon {
            height: 20px;
            width: 20px;
          }
        }
      }
    }
  }

  .tablet({
    .top {
      .inner {
        padding: 128px 26px;
      }
    }
    .lower {
      .inner {
        flex-direction: row;
        justify-content: space-between;
      }
    }
  });

  .desktop({
    .top {
      .inner {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .logo {
          flex: 1;
          padding: 8px 32px;
        }
        .contact {
          flex: 2;
        }
        nav {
          flex: 3;
        }
      }
    }
  });
}
