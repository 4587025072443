.block.stats-counter {
  .inner {
    .content {
      padding: 32px 10px;
      ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .stat-item {
          .stat-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .number {
              font-size: 3.2rem;
              width: fit-content;
              font-weight: 900;
              line-height: 1;
              position: relative;
              &.plus {
                padding-right: 1ch;
                &::after {
                  content: "+";
                  position: absolute;
                  right: 0;
                  top: 0;
                }
              }
              &.dollar {
                padding-left: 1ch;
                &::before {
                  content: "$";
                  position: absolute;
                  left: 0;
                  top: 0;
                }
              }
            }
            .description {
              font-size: 1.28rem;
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }

  .tablet ({
    .inner {
      .content {
        padding: 32px 15px;
        ul {
          flex-direction: row;
          .stat-item {
            flex: 1;
            .description {
              font-size: 1.6rem;
            }
          }
        }
      }
    }
  });;

  .desktop({
    .inner {
      .content {
        padding: 64px 15px 32px;
        margin: 0 0 32px;
        ul {
          .stat-item {
            margin: 0 15px;
            .stat-wrapper {
              position: relative;
              &::after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                width: 1px;
                height: 100%;
                background: @heading-link;
                opacity: 0.3;
              }
              .number {
                font-size: 6.4rem;
              }
              .description {
                font-size: 1.6rem;
              }
            }
            &:nth-child(1) {
              .stat-wrapper {
                &::before {
                  content: "";
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 1px;
                  height: 100%;
                  background: @heading-link;
                  opacity: 0.3;
                }
              }
            }
          }
        }
      }
    }
  });
}
