.block.investment {
  .card {
    width: 100%;
  }

  .card-inner {
    padding: 32px;
  }

  h2 {
    font-size: 3.52rem;
  }

  h3,
  h4,
  h5 {
    font-size: 1.92rem;
  }

  .title {
    margin-bottom: 12.8px;
  }

  p,
  li {
    font-size: 1.44rem;
  }

  .top .overview {
    padding: 10px;
    p {
      margin: 24px 0;
    }
  }

  .top {
    padding: 10px;
    border-bottom: 1px solid @primary-bg;
  }

  .top .details {
    padding: 74px 0;
    margin: 32px 0;
    background-color: @secondary-bg;
  }

  .details .section {
    padding: 16px;
  }

  .bottom {
    padding: 64px 10px 10px;
  }

  ul {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 20px;
    li {
      width: 100%;
    }
  }

  .tablet({
    .top {
      display: flex;
      flex-direction: row;
      gap: 30px;
      .overview, .details {
        flex: 1;
        .section {
          padding: 16px 64px;
        }
      }
      .overview {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
    ul {
      flex-direction: row;
      li {
        width: 50%;
      }
    }
  });

  .card:nth-child(even) {
    background-color: @page-bg;
    .overview,
    .bottom {
      h2,
      h3,
      h4,
      h5 {
        color: @heading-alt;
      }
      p,
      li {
        color: @text-alt;
      }
    }
  }
}
