.block.team-single {
  margin-bottom: 96px;
  .inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .text-content {
      display: flex;
      flex-direction: column;
      margin: 64px 0;
      padding: 0 10px;
      .title {
        text-align: left;
        padding: 10px;
        margin-bottom: 12.8px;
        * {
          font-size: 2.56rem;
        }
      }
      .text {
        padding: 10px;
        margin: 24px 0 0;
        p {
          margin: 0 0 24px;
        }
      }
    }
    .member-container {
      padding: 0 10px;
      .image-wrapper {
        padding: 10px;
        img {
          width: 100%;
          height: auto;
        }
      }
      .member-info {
        padding: 10px;
        .name {
          margin-bottom: 12.8px;
          * {
            font-size: 2.56rem;
          }
        }
        .position {
          margin-bottom: 12.8px;
          font-style: italic;
          opacity: 0.7;
          * {
            font-size: 1.7rem;
            font-family: @title;
          }
        }
        .body {
          p,
          span,
          li {
            font-size: 1.44rem;
          }
          p {
            margin: 0 0 24px;
          }
        }
      }
    }
  }

  .tablet({
    .inner {
      .text-content {
        flex-direction: row;
        padding: 0 15px;
        .title {
          display: flex;
          align-items: center;
          width: 33.33%;
        }
        .text {
          width: 66.67%;
        }
      }
      .member-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0 15px;
        .image-wrapper {
          padding: 15px;
          width: 33.33%;
        }
        .member-info {
          width: 66.67%;
          padding: 15px;
          .name {
            * {
              font-size: 2.88rem;
            }
          }
          .position {
            * {
              font-size: 2rem;
            }
          }
          .body {
            p,
          span,
          li {
            font-size: 1.6rem;
          }
          }
        }
      }
    }
  });

  .desktop({
    .inner {
      .text-content {
        .title {
          * {
            font-size: 4.8rem;
          }
        }
      }
      .member-container {
        .member-info {
          .name {
            * {
              font-size: 4.8rem;
            }
          }
        }
      }
    }
  });
}
