.block.breadcrumbs {
  background-color: @primary-bg;
  padding: 16px;
  .inner {
    max-width: 1200px;
    ul {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      list-style: none;
      padding: 0 10px;
      li {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        a,
        span {
          font-size: 1.1rem;
          text-transform: uppercase;
        }
        span {
          color: @text;
        }
        a {
          text-decoration: underline;
          color: @heading;
        }
        &:not(:last-child):after {
          content: "/";
          margin: 0 12px;
          color: @heading-link;
        }
      }
    }
  }

  .tablet({
    .inner {
      ul {
        padding: 0 15px;
      }
    }
  });
}
