.block.home-investment {
  margin-bottom: 32px;
  padding: 0 15px 64px;
  &.builder {
    img {
      width: 500px !important;
      margin-bottom: 5px;
    }
  }
  .top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    .title {
      font-size: 3.52rem;
      margin-bottom: 12.8px;
      text-align: center;
    }
    .button {
      margin: 15px 0;
    }
  }
  .bottom {
    .owl-stage-outer {
      display: flex;
    }
    .owl-stage {
      display: flex;
      flex: 1 0 auto;
      height: 100%;
    }
    .owl-item {
      position: relative;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      img {
        aspect-ratio: 3/2;
      }
    }
    .owl-dots {
      text-align: center;
      transform: translateY(-100%);

      .owl-dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #d6d6d6;
        margin: 5px 7px;
        transition: ease all 0.2s;
        &.active {
          background: #869791;
        }
      }
    }
    .owl-nav {
      transform: translateY(100%);
      text-align: center;
      .owl-prev,
      .owl-next {
        margin: 5px;
        transition: ease all 0.2s;
        &:hover,
        &:focus {
          background: @button-bg-hover;
          color: @button-text;
        }
      }
      .disabled {
        opacity: 0.5;
        cursor: default;
        &:hover,
        &:focus {
          background: none;
          color: @text;
          outline: none;
        }
      }
    }
  }
  .tablet({
    padding: 32px 16px;
      .top {
        flex-direction: row;
        justify-content: space-between;
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 15px 48px;
        .title {
          font-size: 2.88rem;
          margin-bottom: 0;
        }
        .button {
          margin: 15px auto;
        }
      }
  });
}
