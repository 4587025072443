.block.portfolio {
  padding: 64px 0;

  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 12.8px;
  }
  h2 {
    font-size: 1.92rem;
  }
  h3 {
    font-size: 1.6rem;
  }
  p {
    font-size: 1.44rem;
  }
  ul,
  p {
    margin-bottom: 24px;
  }
  ul {
    list-style-position: outside;
    padding-left: 20px;
  }
  li {
    font-size: 1.6rem;
  }

  .map-container {
    width: 100%;
    padding: 0 15px;
    overflow: hidden;
    #portfolio-map {
      width: 100%;
      height: auto;
      max-height: 800px;
      position: relative;
    }
  }

  .inner {
    padding: 0 10px;
  }

  .property-card {
    padding: 32px 0;
    border-bottom: 4px solid @primary-bg;
    display: none;
    flex-direction: column;
  }


  .image-wrapper {
    padding: 0 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    img {
      max-width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  .property-details {
    padding: 0 32px;
  }

  .labels {
    padding: 10px 0;
    .label {
      font-size: 0.96rem;
      padding: 8px 16px;
      color: #ffffff;
      border-radius: 16px;
      margin-right: 5px;
      text-transform: uppercase;
      &.default {
        background: @button-bg;
      }
      &.development {
        background: #2ecc71;
      }
      &.acquisition {
        background: #3498db;
      }
      &.sold {
        background: #e74c3c;
      }
    }
  }

  .website {
    display: none;
  }

  .button {
    font-size: 1.28rem;
  }

  .tablet({
    .property-card {
      flex-direction: row;
      .image-wrapper {
        width: 41.67%;
      }
      .property-details {
        width: 58.33%;
      }
    }
    h2 {
      font-size: 2.56rem;
    }
    h3 {
      font-size: 1.92rem;
    }
    p {
      font-size: 1.6rem;
    }
    .button {
      font-size: 1.6rem;
    }
    .image-wrapper {
      align-items: start;
    }
  });
}
