.block.history {
  background-color: @secondary-bg;
  &.builder {
    .swiper-wrapper {
      display: flex !important;
      flex-wrap: wrap !important;
      gap: 10px !important;
      .swiper-slide {
        opacity: 1 !important;
      }
    }
    .timeline-btns-wrapper {
      pointer-events: none !important;
    }
  }
  .inner {
    padding: 0 10px;
    min-height: 450px;
    .animate {
      animation: fade-in 0.3s ease-in-out forwards 0.4s;
    }
    .title-wrapper {
      background-color: @heading;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 320px;
      border: 8px solid @primary-bg;
      margin-bottom: 16px;
      width: 100%;
      h1,
      h2,
      h3,
      h4,
      h5 {
        font-size: 3.52rem;
        text-align: center;
      }
    }
    .timeline-container {
      width: 100%;
      padding: 0 10px;
      .timeline {
        position: relative;
        padding: 0 32px;
        .timeline-years {
          width: 100%;
          padding: 32px;
          .swiper-slide {
            top: 0;
            position: relative;
            width: auto;
            min-height: 37px;
            height: 100%;
            margin-right: 70px;
            opacity: 0.4;
            cursor: pointer;
            .year {
              font-size: 3.2rem;
              transform: scale(0.75);
              font-family: "Times New Roman", serif;
              font-weight: 700;
              font-style: italic;
              color: @heading;
              position: relative;
              transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
            }
            &.swiper-slide-active {
              opacity: 1;
              .year {
                transform: scale(1);
                scale: 1;
              }
              &::after {
                background-color: @text-link-hover;
                bottom: -12px;
              }
            }
            &::after {
              content: "";
              position: absolute;
              background-color: @text-link;
              width: 0.64rem;
              height: 0.64rem;
              border-radius: 50%;
              bottom: -0.88rem;
              left: 50%;
              transform: translate(-50%, -50%);
              bottom: 0;
              transition: bottom 0.3s ease-in-out;
            }
          }
          &::after {
            content: "";
            width: 100%;
            height: 2px;
            position: absolute;
            bottom: 5px;
            opacity: 0.5;
            border-bottom: 2px dotted @text-link-hover;
          }
          &.hop::after {
            animation: hop 0.3s ease-in-out;
          }
        }
        .timeline-content {
          padding: 16px;
          width: 100%;
          &.hop {
            animation: hop 0.3s ease-in-out;
          }
          .swiper-wrapper {
            .swiper-slide {
              .slide-content {
                .title {
                  margin-bottom: 12.8px;
                }
                h1,
                h2,
                h3,
                h4,
                h5 {
                  font-size: 2.56rem;
                }
                p,
                span,
                li,
                a {
                  font-size: 1.44rem;
                }
                ul {
                  list-style-position: outside;
                  padding-left: 17px;
                }
                li {
                  margin-bottom: 24px;
                  * {
                    display: inline;
                  }
                }
              }
            }
          }
        }
        .timeline-btns-wrapper {
          position: absolute;
          height: 400px;
          top: 0;
          left: 0;
          width: 100%;
          .timeline-btn {
            position: absolute;
            top: 50%;
            z-index: 2;
            cursor: pointer;
            background-color: @text-link-hover;
            border-radius: 50%;
            width: 28px;
            height: 28px;
            font-size: 1.5rem;
            padding: 5px;
            display: block;
            transition: background-color 0.2s ease-in-out;
            &::after {
              font-family: "Font Awesome 5 Free";
              font-weight: 900;
              color: @text-alt;
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              transition: color 0.2s ease-in-out;
            }
            &:focus,
            &:hover {
              background-color: @text-alt;
              &::after {
                color: @text-link-hover;
              }
            }
            &.timeline-prev-btn {
              left: 0;
              &::after {
                content: "\f0d9";
              }
            }
            &.timeline-next-btn {
              right: 0;
              &::after {
                content: "\f0da";
              }
            }
            &.swiper-button-disabled {
              opacity: 0.3;
              cursor: default;
              &:hover,
              &:focus {
                background-color: @text-link-hover;
                outline: none;
                &::after {
                  color: @text-alt;
                }
              }
            }
          }
        }
      }
    }
  }

  .tablet({
    .inner {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 15px;
      .title-wrapper {
        width: 33.3333%;
        h1, h2, h3, h4, h5 {
          font-size: 4.16rem;
        }
      }
      .timeline-container {
        width: 66.6667%;
        padding: 0 15px;
      }
    }
  });

  .desktop({
    padding: 64px;
    .inner {
      .title-wrapper {
        height: 480px;
      }
      .timeline-container {
        .timeline {
          .timeline-content {
            width: 100%;
            .swiper-wrapper {
              .swiper-slide {
                .slide-content {
                  padding: 32px;
                  h1,
                  h2,
                  h3,
                  h4,
                  h5 {
                    font-size: 2.88rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  });
}
