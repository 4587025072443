.block.parallax-block {
  background-image: url("/assets/img/parallax_bg.jpeg");
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 640px;

  .tablet({
    background-attachment: fixed;
  });
}
