.block.hero {
  position: relative;
  height: calc(100vh + 16px);
  height: calc(100dvh + 16px);
  min-height: 500px;
  width: 100%;
  background-color: #767676;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #4a4e4e 0%, #4a4e4e 100%);
    opacity: 0.3;
  }

  .divider {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 16px;
    z-index: 1;
  }

  .inner {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      z-index: 1;
      .image-wrapper {
        margin-bottom: 20px;
        max-width: 90%;
        img {
          width: 100%;
          height: auto;
        }
      }
      .text {
        font-weight: bold;
        letter-spacing: 3px;
        text-transform: uppercase;
        padding-left: 16px;
        margin-bottom: 30px;
        &.animate {
          animation: fade-in-down 0.8s ease-in-out forwards;
        }
        * {
          font-size: 2.4rem;
          line-height: 1.115;
        }
      }
    }
    .to-content {
      position: absolute;
      opacity: 0;
      bottom: 32px;
      width: 3.2rem;
      height: 3.2rem;
      z-index: 1;
      &.animate {
        animation: fade-in-down 0.8s ease-in-out forwards 0.4s;
      }
      svg {
        fill: white;
      }
      &:hover {
        scale: 1.1;
      }
    }
  }

  .tablet({

  });

  .desktop({
    background-attachment: fixed;
    .inner {
      .content {
        .text {
          * {
            font-size: 3.2rem;
          }
        }
      }
    }
  });
}
