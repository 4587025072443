.block.home-intro {
  .text {
    margin-bottom: 24px;
    padding: 64px 25px 0;
    p,
    * {
      font-size: 2rem;
    }
  }

  .tablet({
    .text {
      padding: 64px 30px 0;
    }
  });
}
