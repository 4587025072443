.block.news {
  padding: 64px 0;

  &.builder {
    .reader {
      article {
        display: block !important;
        &:not(:last-child)::after {
          content: "";
          display: block;
          width: calc(100% - 32px);
          margin: 0 auto;
          height: 2px;
          background-color: @text-link-hover;
          margin-bottom: 24px;
        }
        h2,
        h3,
        h4,
        h5 {
          text-transform: none;
        }
      }
    }
  }

  .inner {
    padding: 0 10px;
  }

  .reader,
  .list {
    border: 1px solid #e6e6e6;
  }

  .list {
    max-height: 400px;
    overflow-y: auto;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        position: relative;
        .empty {
          padding: 20px 24px;
          text-align: center;
          font-size: 1.2rem;
          color: @text;
        }
        button {
          background-color: unset;
          border: none;
          text-align: left;
          padding: 20px 24px;
          font-size: 1.2rem;
          line-height: 1;
          color: @text;
          display: block;
          transition: all 0s;
          width: 100%;
          &.selected {
            background-color: @primary-bg;
            border-left: 4px solid @text-link;
          }
          &:hover,
          &:focus {
            background-color: unset;
            color: @text-link;
          }
        }

        &:not(:last-child):after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: calc(100% - 48px);
          height: 1px;
          background-color: #e6e6e6;
        }
      }
    }
  }

  .reader {
    padding: 16px;
    position: relative;
    h2,
    h3,
    h4,
    h5 {
      font-size: 2.4rem;
      line-height: 1;
    }

    article {
      &.hidden {
        display: none;
      }
    }

    .title {
      margin-bottom: 32px;
    }

    .body {
      p,
      li,
      a {
        font-size: 1.44rem;
        letter-spacing: unset;
        line-height: 1.5;
      }
    }
  }

  .tablet({
    .inner {
      padding: 0 15px;
      display: flex;
      flex-direction: row;
      gap: 30px;
      .list {
        flex: 1;
        max-height: 600px;
      }
      .reader {
        flex: 3;
        p,
        li,
        a {
          font-size: 1.6rem;
        }
      }
    }
  });
}
