.block.subpage-hero-text,
.block.subpage-hero-image {
  position: relative;
  height: 496px;
  background-color: #767676;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #313434 0%, #313434 100%);
    opacity: 0.6;
  }
  .divider {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 16px;
    z-index: 1;
  }
  .content {
    z-index: 1;
    text-align: center;
    padding: 0 10px 1ch;
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: #fff;
      margin: 0;
    }
    .image-wrapper {
      width: 300px;
      height: auto;
      img {
        max-width: 100%;
        height: auto;
      }
    }

    &.animate {
      animation: fade-in-down 0.8s ease-in-out forwards;
    }
  }

  .tablet({
    .content {
      padding: 0 15px 1ch;
    }
  });
}
