form {
  margin: auto;
  max-width: 780px;
  padding: 12px;

  .form-group {
    padding: 6px 0;
  }

  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"],
  input[type="search"],
  input[type="tel"],
  input[type="phone"],
  select,
  textarea {
    box-sizing: border-box;
    width: 100%;
    font-size: 1.6rem;
    font-family: @main;
    line-height: 1.5;
    height: 5rem;
    margin-bottom: 16px;
    padding: 8px;
    border: 1px solid @heading-link;
    box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
    border-radius: 0;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
    text-indent: 20px;
    background-color: #fff;
    font-family: @main;

    &::placeholder {
      opacity: 0.5;
    }

    &:focus,
    &:active {
      border-color: @heading-link;
      box-shadow: 0 0 5px @heading-link;
      outline: @text-link auto 3px;
    }

    &[disabled] {
      &:focus,
      &:active {
      }
    }
  }

  select {
  }

  textarea {
    box-sizing: border-box;
    min-height: 120px;
    max-height: 400px;
    width: 100%;
    padding: 15px 20px;
    resize: vertical;
    text-indent: 0px;
    background-color: #fff;
    font-family: @main;
  }

  input[type="checkbox"] {
  }

  input[type="radio"] {
  }

  input[type="submit"],
  input[type="button"],
  input[type="reset"],
  button {
    .button;
    border: 0;
    padding: 24px 64px;
    font-size: 1.28rem;
    font-weight: 900;
    margin: 6px 0 0;
  }

  input[type="color"] {
  }

  input[type="file"] {
  }

  input[type="range"] {
  }

  input[type="password"] {
    letter-spacing: 0.3em;
  }

  ::-webkit-input-placeholder {
    //color: #fff;
  }

  ::-moz-placeholder {
    //color: #fff;
  }

  :-ms-input-placeholder {
    //color: #fff;
  }

  :-moz-placeholder {
    //color: #fff;
  }

  label {
    font-size: 1.4rem;
    line-height: 1.8;
    font-weight: 400;
  }

  .field-wrapper {
    padding-bottom: 25px;
  }
}

@media screen and (min-width: 768px) {
  .fields,
  reform {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  }

  .iwrap,
  .form-group,
  .field-wrap {
    width: 100%;
    padding-bottom: 12px;

    &.quarter {
      width: 23%;
    }

    &.half {
      width: 48%;
    }

    &.third {
      width: 73%;
    }

    &.one-third {
      width: 31%;
    }

    &.two-thirds {
      width: 65%;
    }

    &.textarea,
    &.submit,
    &.full {
      width: 100%;
    }

    &.submit {
      padding-left: 0;
      margin-left: 0;
    }
  }
}
