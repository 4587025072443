@import "../base/_utilities";

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;900&display=swap");

// universal
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:focus {
    outline: @button-bg auto 3px;
  }
}

img {
  max-width: 100%;
}
// links
a {
  text-decoration: none;
  transition: ease all 0.3s;
  outline: none;
  letter-spacing: 1px;
  color: @text-link;
  cursor: pointer;
  .fontSize(1.6,1.6,1.6);
  &:hover,
  &:focus {
    text-decoration: none;
    color: @text-link-hover;
  }

  &:active {
    text-decoration: none;
  }

  /* mobile tap color */
  &:link {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.3);
  }
}

// headings
.block,
.article {
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  .title {
    text-rendering: optimizelegibility;
    margin: 0;
    font-weight: 900;
    line-height: 1;
    font-family: @title;
    color: @heading;
    text-transform: uppercase;
  }
}

h1,
.h1 {
  .fontSize(2.88,2.88,2.88);
}

h2,
.h2 {
  .fontSize(2.56,2.56,2.56);
}

h3,
.h3,
h4,
.h4,
h5,
.h5 {
  .fontSize(1.92,1.92,1.92);
}

button,
.button,
a.button {
  position: relative;
  display: inline-block;
  line-height: 24px;
  padding: 12px 24px;
  background: @button-bg;
  border: 2px solid @button-border;
  color: @button-text;
  font-size: 1.6rem;
  // font-weight: bold;
  border-radius: 1px;
  transition: ease all 0.4s;
  text-align: center;
  margin: auto;
  cursor: pointer;
  font-family: @main;
  &.ghost {
    background: none;
    color: @button-bg;
    border: 0;
    border-bottom: 3px solid @button-border;
  }
  &.dark-btn {
    border: 1px solid @button-border;
    border-radius: 360px;
    font-weight: 900;
    padding: 12px 64px;
  }

  &.inline {
    display: inline-block;
  }

  &:hover {
    background: @button-bg-hover;
  }
}

.outline {
  background: none;
  border: 2px solid blue;
  color: #000;
}

.divider {
  background: linear-gradient(to right, #f2c94c, #f2994a);
  height: 16px;
}

.block,
.article {
  p,
  li,
  .text {
    font-family: @main;
    font-size: 1.6rem;
    margin: 0;
    color: @text;
  }

  .text-alt {
    h1,
    h2,
    h3,
    h4,
    h5,
    .title {
      color: @heading-alt;
    }
    p,
    li,
    span {
      color: @text-alt;
    }
  }
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style-position: inside;
}

p,
ul,
ol,
dl {
  // + p, + ul, + ol, + dl {
  // 	margin-top: 1.5em;
  // }
}

nav,
.nav,
nav ul,
.nav ul {
  list-style-type: none;
  padding-left: 0;
}

hr {
  border: none;
  background: #eee;
  height: 2px;
  width: 60px;
  margin: 35px auto 0;
}

.alignleft,
.alignright {
  margin-bottom: 1em;
}

address {
  font-style: normal;
}

.center {
  text-align: center;
}

.upper {
  text-transform: uppercase;
}

.screen-reader-text {
  position: absolute !important;
  left: -999em;
}

.html-times {
  font-size: 1.5em;
  vertical-align: sub;
}
