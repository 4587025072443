.block.services {
  padding: 64px 16px;
  background-color: @secondary-bg;
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 12.8px;
  }
  p {
    margin-bottom: 24px;
  }
  .inner {
    padding: 0 10px;
    .service-container {
      padding: 10px;
      .heading {
        h2,
        h3,
        h4,
        h5 {
          font-size: 2.56rem;
        }
      }
      ul {
        list-style-type: none;
        li {
          display: flex;
          flex-direction: row;
          padding: 16px 16px 16px 0;
          border-bottom: 1px solid @primary-bg;
          .icon {
            margin-right: 32px;
            i {
              width: 60px;
              font-size: 46px;
            }
          }
          .details {
            h2,
            h3,
            h4,
            h5 {
              font-size: 1.6rem;
            }
            p,
            a {
              font-size: 1.44rem;
            }
          }
        }
      }
    }
    .image-container {
      height: 320px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        position: relative;
        object-fit: cover;
        object-position: bottom center;
      }
      &::before {
        content: "";
        top: 32px;
        right: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background: @heading;
      }
    }
  }

  .tablet({
    padding: 128px 16px;
    .inner {
      .service-container {
        .heading {
          h2, h3, h4, h5 {
            font-size: 2.88rem;
          }
        }
      }
      .image-container {
        height: 640px;
        &::before {
          right: -32px;
        }
      }
    }
  });

  .desktop ({
    .inner {
      display: flex;
      flex-direction: row;
      align-items: center;
      .service-container {
        width: 41.6667%;
      }
      .image-container {
        width: 58.3333%;
        img {
          width: 100%;
        }
      }
    }
  });;
}
