.block.memoriam {
  padding: 32px 0;
  .inner {
    padding: 0 10px;
    .left {
      padding: 10px;
      img {
        max-width: 100%;
        height: auto;
        max-height: 100%;
        object-fit: contain;
      }
    }
    .right {
      background-color: @page-bg;
      padding: 16px;
      .content {
        width: 100%;
        border: 2px solid @text-alt;
        padding: 16px;
        .header {
          padding: 8px;
          margin-bottom: 16px;
          border-bottom: 1px solid @primary-bg;
          h2,
          h3,
          h4,
          h5 {
            font-size: 1.6rem;
          }
        }
        .life-dates {
          * {
            font-size: 1.6rem;
          }
        }
        p,
        li,
        a {
          font-size: 1.44rem;
        }
        h2,
        h3 {
          font-size: 2.56rem;
        }
        h4,
        h5 {
          font-size: 2.4rem;
        }
        .memory-of {
          margin-bottom: 32px;
          .position-emeritus {
            opacity: 0.8;
          }
        }
        .biography {
          p {
            margin-bottom: 24px;
          }
        }
      }
    }
  }
  .tablet ({
    .inner {
      display: flex;
      flex-direction: row;
      .left {
        flex: 1;
        display: flex;
        align-items: center;
      }
      .right {
        flex: 2;
      }
    }

  });;

  .desktop ({
    .inner {
      .left {}
      .right {
        .content {
          padding: 48px;

        }
      }
    }

  });;
}
