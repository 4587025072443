.block.subsidiary {
  padding: 16px 0;

  .inner {
    padding: 48px 32px;
    h2,
    h3,
    h4,
    h5 {
      font-size: 2.56rem;
    }
    p,
    li {
      font-size: 1.44rem;
    }
    p,
    .button-wrapper {
      margin-bottom: 24px;
      a {
        font-size: 1.28rem;
      }
    }
    .statement {
      margin-bottom: 48px;
    }

    .content-wrapper {
      margin-bottom: 32px;
      .image-wrapper {
        width: 100%;
        img {
          max-height: 300px;
          padding: 20px;
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
      .content {
        background-color: @primary-bg;
        padding: 48px;
        .title {
          margin-bottom: 12.8px;
          padding-bottom: 16px;
          border-bottom: 8px solid @text-link;
        }
        .text {
          font-size: 1.44rem;
        }
      }
    }
  }

  .tablet ({
    .inner {
      p,
      li {
        font-size: 1.6rem;
      }
    }
  });;

  .desktop({
    .inner {
      .content-wrapper {
        display: flex;
        flex-direction: row;
        .image-wrapper {
          flex: 1;
        }
        .content {
          flex: 2;
          .button-wrapper {
            a {
              font-size: 1.6rem;
            }
          }
        }
      }

    }
  });
}
