.block.team-all {
  margin-bottom: 96px;
  &.builder {
    .inner {
      .team-container {
        ul {
          row-gap: 60px;
          li {
            position: relative;
            cursor: pointer;
            a {
              position: absolute;
              top: -50px;
              left: 0;
              width: fit-content;
              height: fit-content;
              background-color: #D0D0D0;
              transform: translate(0) !important;
              color: black;
              padding: 10px;
              opacity: 1;
            }
            &:hover,
            &:focus, &:focus-within {
              figure {
                transform: translate(0) !important;
              }
              figure {
                z-index: 1;
                .image-wrapper {
                  opacity: 1 !important;
                  border: none !important;
                }
              }
            }
          }
        }
      }
    }
  }
  .inner {
    .text-content {
      display: flex;
      flex-direction: column;
      margin: 64px 0;
      padding: 0 10px;
      .title {
        text-align: left;
        padding: 10px;
        margin-bottom: 12.8px;
        * {
          font-size: 2.56rem;
        }
      }
      .text {
        padding: 10px;
        margin: 24px 0 0;
        p {
          margin: 0 0 24px;
          font-size: 1.44rem;
        }
      }
    }
    .team-container {
      padding: 0 10px;
      ul {
        list-style: none;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        row-gap: 32px;
        li {
          display: block;
          width: 100%;
          padding: 0 10px;
          position: relative;
          &.animate {
            animation: fade-in 0.3s ease-in-out forwards;
          }
          a {
            transition: all 0.4s;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            opacity: 0;
          }
          figure {
            transition: all 0.4s;
            .image-wrapper {
              position: relative;
              transition: all 0.4s;
              img {
                width: 100%;
                height: auto;
              }
              &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 8px solid #ffffff;
                opacity: 0.2;
                pointer-events: none;
              }
            }
            figcaption {
              border-left: 6px solid @text-link;
              padding: 32px;
              background-color: @primary-bg;
              text-align: center;
              margin-top: -6px;
              position: relative;
              .name {
                * {
                  text-transform: capitalize;
                  font-family: @main;
                  line-height: 1.5;
                }
              }
              .position {
                * {
                  font-size: 1.28rem;
                  line-height: 1;
                  opacity: 0.5;
                  font-weight: 900;
                }
              }
            }
          }
          &:hover,
          &:focus-within {
            a,
            figure {
              transform: translateY(-16px);
            }
            figure {
              .image-wrapper {
                opacity: 0.4;
                border: 12px solid #ffffff;
              }
            }
          }
        }
      }
    }
  }

  .tablet({
    .inner {
      .text-content {
        flex-direction: row;
        padding: 0 15px;
        .title {
          display: flex;
          align-items: center;
          width: 33.33%;
        }
        .text {
          width: 66.67%;
        }
      }
      .team-container {
        ul {
          flex-direction: row;
            li {
              width: 50%;
                figure {
                  figcaption {
                    margin-left: 48px;
                    margin-top: -32px;
                  }
              }
            }
        }
      }
    }
  });

  .desktop({
    .inner {
      .text-content {
        .title {
          * {
            font-size: 4.8rem;
          }
        }
      }
      .team-container {
        ul {
          flex-direction: row;
            li {
              width: 33.33%;
                figure {
                  figcaption {
                    margin-left: 48px;
                    margin-top: -32px;
                  }
              }
            }
          }
        }
    }
  });
}
