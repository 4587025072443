.block.charity {
  padding: 16px 0;

  .inner {
    padding: 48px 32px;
  }

  h2,
  h3,
  h4,
  h5,
  .title {
    margin-bottom: 12.8px;
  }

  h2 {
    font-size: 3.52rem;
  }

  h3,
  h4,
  h5 {
    font-size: 2.56rem;
  }

  p {
    margin-bottom: 24px;
    font-size: 1.44rem;
  }

  .button {
    margin-bottom: 24px;
    font-size: 1.28rem;
  }

  .intro {
    margin-bottom: 48px;
  }

  .charity-card {
    margin-bottom: 32px;
  }

  .charity-image {
    height: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      padding: 20px;
      height: 300px;
      object-fit: contain;
    }
  }
  .content {
    background-color: @primary-bg;
    padding: 48px;
    .title {
      border-bottom: 8px solid @text-link;
      padding-bottom: 16px;
      h2,
      h3,
      h4,
      h5 {
        margin-bottom: 0;
      }
    }
  }

  .tablet ({
    h2 {
      font-size: 4.16rem;
    }
    h3, h4, h5 {
      font-size: 2.88rem;
    }
    p, .button {
      font-size: 1.6rem;
    }
    .charity-image {
      height: 500px;
    }
  });;

  .desktop({
    .charity-card {
      display: flex;
      flex-direction: row;
      .charity-image {
        width: 33.3333%;
      }
      .content {
        width: 66.6667%;
      }
    }
  });
}
