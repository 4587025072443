.block.case-studies {
  padding: 16px 0;
  h2,
  h3,
  h4,
  h5 {
    font-size: 2.88rem;
  }
  &.builder {
    .swiper-wrapper {
      display: flex !important;
      flex-wrap: wrap !important;
      gap: 16px !important;
    }
    .swiper-btn {
      display: none;
    }
  }

  .inner {
    padding: 0 10px;
    .property {
      padding: 64px 32px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .title {
        padding-bottom: 16px;
        margin-bottom: 32px;
        border-bottom: 8px solid @text-link;
      }
      .details-grid {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: calc(50% - 2.5px);
          text-align: center;
          .icon {
            width: 48px;
            height: 60px;
            svg {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }

    .case-study-example {
      margin-bottom: 128px;
    }

    .before-after {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        background-color: @button-bg;
        width: 100%;
        height: 100%;
        top: 32px;
        left: 0;
        z-index: -1;
      }
    }

    .swiper-wrapper {
      .swiper-slide {
        padding: 0 10px;
        position: relative;
        .image-wrapper {
          height: 320px;
          width: 100%;
          user-select: none;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
        .caption {
          position: absolute;
          right: 16px;
          bottom: 16px;
          background: @button-bg;
          border: 8px solid @button-text;
          padding: 32px 64px;
          user-select: none;
          * {
            font-size: 2.4rem;
          }
        }
      }
    }
    .swiper-btn {
      color: @button-text;
      &.swiper-button-prev {
        left: 10px;
      }
      &.swiper-button-next {
        right: 10px;
      }
    }
    .swiper-pagination-bullet {
      &.swiper-pagination-bullet-active {
        background: @text-link;
      }
    }
  }

  .tablet({
    .inner {
      .case-study-example {
        display: flex;
        flex-direction: row;
        &:nth-child(even) {
          flex-direction: row-reverse;
        }
        .property {
          width: 40%
        }

        .before-after {
          width: 60%;
          .swiper-wrapper {
          .swiper-slide {
            .image-wrapper {
              height: 640px;
            }
          }
        }
        }
      }
    }
  });
}
