.block.topbar {
  padding: 16px 8px;
  min-width: 300px;
  position: absolute;
  overflow-x: clip;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 999;

  .skip-link {
    position: absolute;
    left: -9999px;
    transition: all 0s ease-out;
  }

  .skip-link:focus {
    position: absolute;
    color: #ffffff;
    background-color: #000;
    left: auto;
    top: -5px;
    width: 30%;
    height: auto;
    overflow: auto;
    margin: 10px 35%;
    padding: 5px;
    border-radius: 15px;
    border: 4px solid #ff0;
    text-align: center;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 1.2em;
    z-index: 999;
  }

  a {
    display: block;
    position: relative;
    font-weight: 900;
    text-align: center;
  }

  .container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-height: 40px;
    .logo {
      position: relative;
      width: 176px;
      left: 0;
      filter: brightness(0) invert(1);
    }
    nav {
      display: none;
    }
    .menu-btn {
      position: absolute;
      right: 0;
      button {
        background-color: transparent;
        padding: 16px;
        border: none;
        z-index: 2;
        span {
          height: 1px;
          background-color: @heading-alt;
          width: 30px;
          display: block;
          transition: background-color 0.4s ease-in-out;
          &:not(:last-child) {
            margin-bottom: 8px;
          }
        }
      }
      &.open {
        button {
          z-index: 3;
          span {
            background-color: @page-bg;
          }
          &::before {
            color: @page-bg;
          }
        }
      }
    }
  }

  .aside-menu {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    .overlay {
      position: fixed;
      visibility: hidden;
      height: 100%;
      width: 100%;
      background: linear-gradient(to right, #313434 0%, #313434 100%);
      opacity: 0;
      transition: all 0.4s ease-in-out;
    }
    .side-container {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      min-height: 100vh;
      background-color: @primary-bg;
      background-image: url("/assets/img/nav-bg.jpg");
      padding: 96px 16px 0;
      transform: translateX(100%);
      transition: all 0.4s ease-in-out;
      .logo-wrapper {
        width: 80px;
        position: absolute;
        top: 24px;
        left: 32px;
        a {
          display: block;
          img {
            width: 100%;
            object-fit: contain;
          }
        }
      }
      .content {
        position: relative;
        margin-bottom: 10px;
        .aside-inner {
          .image {
            display: none;
          }
          .nav-list {
            ul {
              padding: 10px;
              list-style: none;
              text-align: center;
              li {
                border-bottom: 1px solid @heading;
                a {
                  color: @heading;
                  padding: 8px 0;
                  display: inline-block;
                  transition: 0.4s color ease-in-out;
                  &:hover {
                    color: @text-link;
                  }
                }
              }
            }
          }
        }
        .details {
          text-align: center;
          * {
            font-size: 1.44rem;
            font-weight: 400;
            line-height: 1.5;
          }
        }
      }
    }
    &.open {
      pointer-events: all;
      .overlay {
        visibility: visible;
        opacity: 0.8;
      }
      .side-container {
        transform: translateX(0);
      }
    }
  }

  .tablet({
    padding: 48px 32px;
    .container {
      .logo {
        position: absolute;
        width: 176px;
        left: 0;
      }
      .menu-btn {
        top: -8px;
        button::before {
          content: "Menu";
          position: absolute;
          left: -48px;
          top: 50%;
          transform: translateY(-50%);
          transition: color 0.4s ease-in-out;
        }
      }
    }
    .aside-menu {
      .side-container {
        width: 85%;
        .content {
        .aside-inner {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: center;
          align-items: center;
            .image {
              display: block;
              flex: 1;
              .image-wrapper {
                display: block;
                padding: 15px;
                width: 100%;
                height: 100%;
                img {
                  width: 100%;
                  object-fit: contain;
                }
              }
            }
            .nav-list {
              flex: 1;
            }
        }
          .details {
            text-align: end;
            padding: 6px 14px;
            .contact-wrapper {
              .item {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: end;
              }
            }
          }
        }
      }
    }
  });

  .desktop({
    .aside-menu {
      .side-container {
        padding: 96px 32px 0 16px;
        .logo-wrapper {
          width: 128px;
        }
        .content {
          .aside-inner {
            .nav-list {
              ul {
                li {
                  a {
                    font-size: 1.92rem;
                  }
                }
              }
            }
          }
          .details {
            .contact-wrapper {
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              align-items: end;
              .item {
                padding: 0 16px;
                border-right: 1px solid @heading;
              }
            }
          }
        }
      }
    }
  });

  .vp(1200px, {
    .container {
      nav {
        display: block;
        margin: 0 130px 0 180px;
        ul {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          li {
            text-align: center;
            width: fit-content;
            a {
              padding: 8px 16px;
              text-shadow: 0 0 1px rgba(255, 255, 255, 0.3);
              transition: color 0.3s;
              font-size: 1.28rem;
              color: @heading-alt;
              &::after {
                position: absolute;
                bottom: -10px;
                left: 50%;
                transform: translateX(-50%);
                color: transparent;
                content: "•";
                text-shadow: 0 0 transparent;
                font-size: 1.2em;
                transition: text-shadow 0.3s, color 0.3s;
                pointer-events: none;
              }
              &:hover::after {
                color: @text-link;
                text-shadow: 10px 0 @text-link, -10px 0 @text-link;
              }
            }
          }
        }
      }
    }
    .aside-menu {
      .side-container {
        display: flex;
        padding: 0 32px 0 16px;
        .content {
        display: flex;
        flex-direction: row;
        .aside-inner {
          flex: 3;
          .nav-list {
            ul {
              text-align: end;
            }
          }
        }
        .details {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .item {
            margin-bottom: 5px;
          }
            .contact-wrapper {
              flex-direction: column;
              .item, .item * {
                display: inline;
              }
            }
        }
        }
      }
    }
  });

  .vp(1920px, {
    .aside-menu {
      .side-container {
        width: 50%;
        .content {
          .aside-inner {
            .nav-list {
              ul {
                li {
                  a {
                    padding: 20px 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  })
}
